import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { ApiService } from '../api/api.service';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DataService } from '../storage/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn = false;
  token: any;

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private storage: Storage,
    private data: DataService
  ) { }

  public login(username: string, password: string, idbranch): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'text/javascript',
      'client-keys': environment.API_KEY
    });
    return this.http.post(environment.API_URL + 'user/auth',
      { username, pass: password, branch: idbranch }, { headers }
    ).pipe(
      tap(token => {
        if (token.status === 200) {
          this.storage.set('users', token);
          this.storage.set('general', token);
          this.storage.set('token', token)
            .then(
              () => {
                // console.log(token['accessToken']);
                // this.events.publish('user:created', token);
              },
              error => console.error('Error storing item', error)
            );
          this.token = token;
          this.isLoggedIn = true;
          return token;
        } else {
          return token;
        }
      }),
    );
  }

  logout() {
    this.storage.clear();
    this.isLoggedIn = false;
    delete this.token;
    return;
  }


  getToken() {
    return this.storage.get('general').then(
      data => {
        this.token = data;
        if (this.token != null) {
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      },
      error => {
        this.token = null;
        this.isLoggedIn = false;
      }
    );
  }

  getTokenPartimer() {
    return this.storage.get('partimer').then(
      data => {
        this.token = data;
        if (this.token != null) {
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      },
      error => {
        this.token = null;
        this.isLoggedIn = false;
      }
    );
  }

  getSessionPartimer() {
    return this.data.getPartimerSession().then((r) => {
      console.log(r);
      this.token = r;
      if (r !== null || r !== undefined || r !== 'null' || r !== '') {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }
}
