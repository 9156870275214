import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/helpers/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing/index',
    pathMatch: 'full'
  },
  {
    path: 'home/:id',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'landing/:id',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'registers',
    loadChildren: () => import('./auth/registers/registers.module').then(m => m.RegistersPageModule)
  },
  {
    path: 'user/:id',
    loadChildren: () => import('./auth/user/user.module').then(m => m.UserPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'gwptintro',
    loadChildren: () => import('./pages/general/test/gwpt/gwptintro/gwptintro.module').then(m => m.GwptintroPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gwptquiz/:mod/:rf',
    loadChildren: () => import('./pages/general/test/gwpt/gwptquiz/gwptquiz.module').then(m => m.GwptquizPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rightmenu',
    loadChildren: () => import('./menu/rightmenu/rightmenu.module').then(m => m.RightmenuPageModule)
  },
  {
    path: 'errors',
    loadChildren: () => import('./pages/errors/errors.module').then(m => m.ErrorsPageModule)
  },
  {
    path: 'res/:rf/:ac/:conf',
    loadChildren: () => import('./pages/res/res.module').then(m => m.ResPageModule)
  },
  {
    path: 'candidate/:id',
    loadChildren: () => import('./pages/partimer/pintro/pintro.module').then(m => m.PintroPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pvideo/:rf/:rd',
    loadChildren: () => import('./pages/partimer/pvideo/pvideo/pvideo.module').then(m => m.PvideoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'intropersonal/:rf/:rd',
    loadChildren: () => import('./pages/partimer/ptpersonality/intropersonal/intropersonal.module').then(m => m.IntropersonalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ppersonalquiz/:rf/:rd',
    loadChildren: () => import('./pages/partimer/ptpersonality/ppersonalquiz/ppersonalquiz.module').then(m => m.PpersonalquizPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pwptintro/:rf/:rd',
    loadChildren: () => import('./pages/partimer/pwpt/pwptintro/pwptintro.module').then(m => m.PwptintroPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pwptquiz/:rf/:rd',
    loadChildren: () => import('./pages/partimer/pwpt/pwptquiz/pwptquiz.module').then(m => m.PwptquizPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'locations',
    loadChildren: () => import('./components/locations/locations.module').then(m => m.LocationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'confirm/:id/:rf',
    loadChildren: () => import('./components/confirm/confirm.module').then(m => m.ConfirmPageModule)
  },
  {
    path: 'pvideointro',
    loadChildren: () => import('./pages/partimer/pvideo/pvideointro/pvideointro.module').then(m => m.PvideointroPageModule)
  },
  {
    // add new
    path: 'wptregister/:id',
    loadChildren: () => import('./hrdwpt/wptregister/wptregister.module').then(m => m.WptregisterPageModule)
  },
  {
    // add new
    path: 'wptintroduction/:ref',
    loadChildren: () => import('./hrdwpt/wptintroduction/wptintroduction.module').then(m => m.WptintroductionPageModule)
  },
  {
    // add new
    path: 'wptquiz/:rf/:rd/:ref',
    loadChildren: () => import('./hrdwpt/wptquiz/wptquiz.module').then(m => m.WptquizPageModule)
  },
  {
    path: 'modalnotif',
    loadChildren: () => import('./hrdwpt/modalnotif/modalnotif.module').then(m => m.ModalnotifPageModule)
  },
  {
    path: 'modalres/:id',
    loadChildren: () => import('./hrdwpt/modalres/modalres.module').then( m => m.ModalresPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
