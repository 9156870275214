import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  Accept: 'text/javascript',
  'client-keys': environment.API_KEY
});

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  API_URL = environment.API_URL;
  API_GENS = environment.API_GEN;
  IMG_URL = environment.IMG_URL;
  //API_URL_MAIL = environment.API_URL_MAIL;

  constructor(
    private http: HttpClient,
    private storage: Storage
  ) { }

  public getQuestion(): Observable<any> {
    return this.http.get(this.API_URL + 'candidate/question', { headers });
  }

  // get Expiredurl
  public getExpiredurl(key: string): Observable<any> {
    return this.http.get(this.API_URL + `validasioffice/expired?key=${key}`, { headers });
  }

  // get EndTimes
  public getEndTimes(id: string): Observable<any> {
    return this.http.get(this.API_URL + `upload/endtime?id=${id}`, { headers });
  }

  public savingDataQuiz(id: any, data: any, totalQ: any): Observable<any> {
    return this.http.post(this.API_URL + 'candidateoffice/saveresults', { id, total: totalQ, data }, { headers });
  }

  public cekForm(ref): Observable<any> {
    return this.http.get(this.API_URL + 'candidate/authcek?ref=' + ref, { headers });
  }

  public checkAuthRef(ref): Observable<any> {
    return this.http.get(this.API_URL + 'candidate/authcekref?ref=' + ref, { headers });
  }

  // getUserData
  public getPartimerData(ref): Observable<any> {
    return this.http.get(this.API_URL + 'user/partimer?ref=' + ref, { headers });
  }

  // Personality Test
  public getListQuestion(): Observable<any> {
    return this.http.get(this.API_URL + 'personality/getquestion', { headers });
  }

  // Personality check
  public personalityCheck(idCand: any): Observable<any> {
    return this.http.get(this.API_URL + 'personality/quizcheck?id=' + idCand, { headers });
  }
  // SavePersonality
  public personalitySave(id: any, datas: any): Observable<any> {
    const json = {
      idCand: id,
      type: 'PersonalityPlus',
      data: datas
    };
    return this.http.post(this.API_URL + 'personality/save', json, { headers });
  }


  // Get Location
  // Province
  public getProvince(): Observable<any> {
    return this.http.get(this.API_GENS + 'location/prov', { headers });
  }

  // City
  public getCity(prov): Observable<any> {
    return this.http.get(this.API_GENS + 'location/city?province=' + prov, { headers });
  }

  // Register
  public userRegister(data): Observable<any> {
    return this.http.post(this.API_URL + 'authoffice/register', data, { headers });
  }



  // Additional data Services
  public additionalData(refs: any, data: any, add1: any, add2: any): Observable<any> {
    return this.http.post(this.API_URL + 'datas/additional', { refs, data, add1, add2 }, { headers });
  }

  // Set default test settings
  public defaultSettingsQuiz(refs: any, data: any): Observable<any> {
    return this.http.post(this.API_URL + 'datas/create', { refs, data }, { headers });
  }

  // get list Settings
  public getQuizSettings(rf): Observable<any> {
    return this.http.get(this.API_URL + 'datas/index?rf=' + rf, { headers });
  }

  // update Quiz settings
  // Set default test settings
  public updateSettingsQuiz(refs: any, data: any): Observable<any> {
    return this.http.post(this.API_URL + 'datas/update', { refs, data }, { headers });
  }

  // Send email Server 1.7
  public sendMails(email): Observable<any> {
   // console.log(email);
   // console.log(this.API_URL + 'authoffice/sendmail?email=' + email);
    return this.http.get(this.API_URL + 'authoffice/sendmail?email=' + email, { headers });
  }

}
