import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/storage/data.service';

@Component({
  selector: 'app-pwptintro',
  templateUrl: './pwptintro.page.html',
  styleUrls: ['./pwptintro.page.scss'],
})
export class PwptintroPage implements OnInit {
  @ViewChild(IonSlides, { static: true }) slides: IonSlides;

  rf: any;

  constructor(
    private router: Router,
    private actvRoute: ActivatedRoute,
    private sts: DataService
  ) {
    this.rf = this.actvRoute.snapshot.paramMap.get('rf');
  }

  ngOnInit() {
  }

  slideChange(ev) {

  }

  onSkip() {
    this.goUrl();
  }

  oNext(s) {
    s.isEnd().then((sx: any) => {
      if (sx) {
        this.goUrl();
      } else {
        s.slideNext();
      }
    });
  }

  goUrl() {
    this.router.navigate(['/pwptquiz', this.rf, this.sts.randomString(10)], {replaceUrl: true});
  }

}
