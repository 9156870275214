import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public quizDefaultSettings = [
    {
      id: 302,
      name: 'Personality',
      url: 'partimer-personlity',
      desc: '',
      status: true
    },
    {
      id: 301,
      name: 'WPT Test',
      url: 'partimer-wpt',
      desc: '',
      status: false
    },
    {
      id: 304,
      name: 'Video',
      url: 'partimer-video',
      desc: '',
      status: false
    }
  ];

  public unlockWpt = [
    {
      id: 302,
      name: 'Personality',
      url: 'partimer-personlity',
      desc: '',
      status: true
    },
    {
      id: 301,
      name: 'WPT Test',
      url: 'partimer-wpt',
      desc: '',
      status: true
    },
    {
      id: 304,
      name: 'Video',
      url: 'partimer-video',
      desc: '',
      status: false
    }
  ];

  public unlockVideo = [
    {
      id: 302,
      name: 'Personality',
      url: 'partimer-personlity',
      desc: '',
      status: true
    },
    {
      id: 301,
      name: 'WPT Test',
      url: 'partimer-wpt',
      desc: '',
      status: true
    },
    {
      id: 304,
      name: 'Video',
      url: 'partimer-video',
      desc: '',
      status: true
    }
  ];

  constructor(
    private storage: Storage
  ) { }

  // Check quiz lock or open
  availableQuiz() {
    const res = {
      q1: 'open',
      q2: 'locked',
      q3: 'locked'
    };

    return res;
  }


  unlockTest(type, action) {
    switch (type) {
      case 'personal':
        this.storage.set(type, action);
        break;
      case 'video':
        this.storage.set(type, action);
        break;
      default:
        break;
    }
  }


  // get Users data
  getUsersData() {
    return this.storage.get('users').then(
      data => {
        return data;
      },
      error => {
        return error;
      }
    );
  }

  // get Users data
  getPartimerSession() {
    const data = localStorage.getItem('partimer');
    return new Promise((resolve, reject) => {
      if (data !== null || data !== undefined || data !== 'null') {
        resolve(data);
      } else {
        reject(null);
      }
    });
  }

  getDriverStorage() {
    return this.storage.driver;
  }

  async removePartimerSession() {
    try {
      const data = await this.storage.remove('partimer');
      // console.log(data);
      return data;
    }
    catch (error) {
      return error;
    }
  }

  // get random string
  randomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // check if https hostname
  public isHttps() {
    return document.location.protocol === 'https:';
  }
}
