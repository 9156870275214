import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NavController, IonSlides, ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/storage/data.service';

@Component({
  selector: 'app-intropersonal',
  templateUrl: './intropersonal.page.html',
  styleUrls: ['./intropersonal.page.scss'],
})
export class IntropersonalPage implements OnInit {

  @ViewChild(IonSlides, { static: true }) slides: IonSlides;
  rf: any;

  // Get Value from propsModal
  @Input() urls: string;

  constructor(
    private router: Router,
    private nav: NavController,
    private actvRoute: ActivatedRoute,
    private sts: DataService,
    private modalController: ModalController
  ) {
    this.rf = this.actvRoute.snapshot.paramMap.get('rf');
  }

  ngOnInit() {
  }

  ionViewDidEnter(){
    this.slides.update();
  }

  slideChange(ev) {

  }

  exitModalPage() {
    this.modalController.dismiss();
  }

  oNext(s) {
    s.isEnd().then((sx) => {
      if (sx) {
        // set value from urls props modal
        this.modalController.dismiss(this.urls);
      } else {
        s.slideNext();
      }
    });
  }

}
