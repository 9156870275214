import { Component, OnInit, NgZone } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/api/auth.service';
import { DataService } from './services/storage/data.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Inbox',
      url: '/folder/Inbox',
      icon: 'mail'
    },
    {
      title: 'Outbox',
      url: '/folder/Outbox',
      icon: 'paper-plane'
    },
    {
      title: 'Favorites',
      url: '/folder/Favorites',
      icon: 'heart'
    },
    {
      title: 'Archived',
      url: '/folder/Archived',
      icon: 'archive'
    },
    {
      title: 'Trash',
      url: '/folder/Trash',
      icon: 'trash'
    },
    {
      title: 'Spam',
      url: '/folder/Spam',
      icon: 'warning'
    }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private authService: AuthService,
    private navCtrl: NavController,
    private data: DataService,
    private zone: NgZone,
    private storage: Storage
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.serviceWorkerHandler();
      this.isAlreadyLogin();
      this.isPartimer();
      // this.getDrivers();
      // this.isHtppsOn();
    });
  }

  // cek if url is https
  isHtppsOn() {
    if (this.data.isHttps() === false) {
      document.location.href = 'https://www.hrd.ptkcg.co.id';
    }
  }

  isAlreadyLogin() {
    this.authService.getToken().then((s) => {
      if (this.authService.isLoggedIn) {
        this.navCtrl.navigateRoot(['/home/index']);
      }
    });
  }

  isPartimerAlreadyLogin() {
    this.authService.getToken().then((s) => {
      if (this.authService.isLoggedIn) {
        this.navCtrl.navigateRoot(['/home/index']);
      }
    });
  }

  isPartimer() {
    this.data.getPartimerSession().then((t) => {
      if (this.authService.isLoggedIn) {
        this.navCtrl.navigateRoot(['/pintro', t]);
      }
    });
  }

  serviceWorkerHandler() {
    if ('caches' in window) {
      caches.keys()
        .then((keyList) => {
          return Promise.all(keyList.map((key) => {
            return caches.delete(key);
          }));
        });
    }
  }

  removeServiceWorkers() {
    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations()
        .then((registrations) => {
          for (let registration of registrations) {
            registration.unregister();
          }
        });
    }
  }

  getDrivers() {
    console.log('Drivers: ' + this.storage.driver);
  }

  ngOnInit() {
    // const path = window.location.pathname.split('folder/')[1];
    // if (path !== undefined) {
    //   this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    // }
  }
}
