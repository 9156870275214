import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';
import { ErrorhandlerService } from './services/err/errorhandler.service';
import { IonicSelectableModule } from 'ionic-selectable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { IntropersonalPageModule } from './pages/partimer/ptpersonality/intropersonal/intropersonal.module';
import { PwptintroPageModule } from './pages/partimer/pwpt/pwptintro/pwptintro.module';
import { UserService } from './services/helpers/user.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(
      {
        name: '__kampret_db',
        driverOrder: ['indexeddb', 'sqlite', 'websql']
      }
    ),
    AppRoutingModule,
    HttpClientModule,
    IonicSelectableModule,
    FormsModule,
    IntropersonalPageModule,
    PwptintroPageModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    UserService,
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: ErrorHandler, useClass: ErrorhandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
