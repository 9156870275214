import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { NavigationError, Router, Event } from '@angular/router';

@Injectable()
export class ErrorhandlerService implements ErrorHandler {
  constructor(
    private injector: Injector,
    private router: Router,
    private zone: NgZone
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationError) {
        this.zone.run(() => {
          this.router.navigate(['errors', { err: event.error}], { skipLocationChange: true});
        });
      }
    });
  }
  handleError(error: any): void {
    // if (error.status === 401) {
    //   this.zone.run(() => this.router.navigate(['/login']));
    // }
  }
}
