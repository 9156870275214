import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/models/user';

@Injectable({
        providedIn: 'root'
})
export class UserService {
        public userSubject: BehaviorSubject<User>;
        public user: Observable<User>;

        constructor(
                private router: Router
        ) {
                this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
                this.user = this.userSubject.asObservable();
        }

        public get userValue(): User {
                return this.userSubject.value;
        }

        logout() {
                // remove user from local storage and set current user to null
                localStorage.removeItem('user');
                // localStorage.clear();
                this.userSubject.next(null);
                this.router.navigate(['/landing']);
        }

        getUserName() {
                const users = this.userSubject.value;
                return users.name;
        }

        getUserId() {
                const users = this.userSubject.value;
                return users.id;
        }
}
